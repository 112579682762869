import Link from 'next/link'
import Image from 'next/image'
import { useTranslations } from 'next-intl'
import { Card, CardContent, CardFooter } from "@/components/ui/card"
import { Badge } from "@/components/ui/badge"
import { Button } from "@/components/ui/button"
import { Tool } from '@/hooks/useDevtool'

const imageLoader = ({ src, width, quality }: { src: string, width: number, quality?: number }) => {
  return `https://static.shanyue.tech/${src}?x-oss-process=image/resize,w_${width}/quality,q_${quality || 75}`
}

export function ToolsBlock({ tools, label, onStar, starTools, className, showThumbnail = true }: { tools: Tool[], label: string, onStar: (tool: Tool) => void, starTools: Tool[], className?: string, showThumbnail?: boolean }) {
  const t = useTranslations('Tools')
  const labelT = useTranslations('Layout.Labels')

  return (
    <div className={className}>
      <div className="flex py-3" id={label}>
        <Badge variant="outline" className="text-orange-500 border-orange-500">
          {labelT(label)} · {t('toolCount', { count: tools.length })}
        </Badge>
      </div>
      <div className="grid grid-cols-1 mb-8 gap-6 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4">
        {tools.map(tool => {
          const isStar = starTools.map(x => x.href).includes(tool.href)
          return (
            <Card key={tool.href} className="group hover:border-orange-400 hover:bg-orange-50 cursor-pointer transition-all duration-300 relative">
              <Button
                size="sm"
                variant="secondary"
                onClick={() => onStar(tool)}
                className="absolute top-2 right-2 z-10"
              >
                {isStar ? t('unstarButton') : t('starButton')}
              </Button>
              {showThumbnail && (
                <CardContent className="p-0">
                  <Link href={tool.href} className="block" prefetch={false}>
                    {!tool.thumbnail.startsWith('images/') ? (
                      <Image
                        alt={`${tool.name} thumbnail`}
                        src={require(`../public/thumbnail/${tool.thumbnail || 'cv.png'}`)}
                        width={340}
                        height={194}
                        placeholder="blur"
                        className="w-full rounded-t-lg"
                      />
                    ) : <Image
                      alt={`${tool.name} thumbnail`}
                      src={tool.thumbnail}
                      loader={imageLoader}
                      width={340}
                      height={194}
                      className="w-full rounded-t-lg"
                    />}
                  </Link>
                </CardContent>
              )}
              <CardFooter className="flex flex-col items-start p-4 rounded-lg">
                <div className="flex justify-between w-full mb-2">
                  <Link href={tool.href} prefetch={false} className="text-lg font-medium group-hover:text-orange-400 transition-colors">
                    {tool.name}
                  </Link>
                </div>
                <p className="text-sm text-muted-foreground">{tool.description}</p>
              </CardFooter>
            </Card>
          )
        })}
      </div>
    </div>
  )
}

export function StarTools({ starTools, onStar, showThumbnail = true }: { starTools: Tool[], onStar: (tool: Tool) => void, showThumbnail?: boolean }) {
  return starTools && starTools.length ?
    <ToolsBlock 
      key="star" 
      label="star" 
      tools={starTools} 
      onStar={onStar} 
      starTools={starTools || []} 
      showThumbnail={showThumbnail}
    /> : null
}
